import React from "react"
import { graphql } from "gatsby"
import { SEO, Layout, PostOptions } from "../components/index"

// Declaring query here allows us to shadow components
export const query = graphql`
  query ($slug: String!) {
    sanityPages(slug: { current: { eq: $slug } }) {
      title
      description
      _rawSections
      slug {
        current
      }
    }

    categories: allSanityCategories(sort: { fields: [name], order: ASC }) {
      ...categories
    }

    authors: allSanityAuthors(sort: { fields: [name], order: ASC }) {
      ...authors
    }

    posts: allSanityPosts(sort: { fields: [publishedAt], order: DESC }) {
      ...posts
    }
  }
`

const BlogTemplate = (props) => {
  let page = props.data.sanityPages
  let items = props.data.posts.edges
  let parent = props.data.sanityPages.slug.current
  let categories = props.data.categories.nodes
  let authors = props.data.authors.nodes

  for (var item in items) {
    if (items[item].node) {
      items[item] = {
        title: items[item].node.title,
        description: `${items[item].node.excerpt}..`,
        slug: { current: `/${parent}/${items[item].node.slug.current}` },
        author: items[item].node.author,
        publishedAt: items[item].node.publishedAt,
        mainImage: items[item].node.mainImage,
        itemImage: { _type: "image" },
        category: items[item].node.category,
      }
      delete items[item].node
    }
  }

  items = { items }
  //filter based on tag, sort based on tag, publisheddate, title - make a postsOptions component and call Grid with updated items

  return (
    <Layout>
      {page.title && <h1>{page.title}</h1>}
      <PostOptions authors={authors} categories={categories} posts={items} />
    </Layout>
  )
}

export default BlogTemplate

export function Head({ data }) {
  let { title, description } = data.sanityPages

  return <SEO title={title} description={description} />
}
